@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@fontsource-variable/roboto-flex/standard.css";

:root {
    --shade-0: #FFF;
    --shade-100: #000000;
    --neutral-50: #F9F9F9;
    --neutral-100: #EDEDEE;
    --neutral-200: #E0E1E1;
    --neutral-300: #D2D3D4;
    --neutral-400: #c3c4c5;
    --neutral-500: #b2b3b5;
    --neutral-600: #9ea0a2;
    --neutral-700: #87898c;
    --neutral-800: #6a6c70;
    --neutral-900: #3C3E44;
    --primary-50: #E5EFFF;
    --primary-100: #CCDEFF;
    --primary-200: #9FB7E3;
    --primary-300: #88A9E3;
    --primary-400: #5B83C9;
    --primary-500: #3E68B2;
    --primary-600: #2F579E;
    --primary-700: #1b4287;
    --primary-800: #0F2F66;
    --primary-900: #001B4B;
    --success-50: #F3FFE5;
    --success-100: #E7FFCC;
    --success-200: #C3E39F;
    --success-300: #B9E388;
    --success-400: #96C95B;
    --success-500: #7CB23E;
    --success-600: #6A9E2F;
    --success-700: #55871B;
    --success-800: #3E660F;
    --success-900: #284B00;
    --warning-50: #fff9e8;
    --warning-100: #FFF1C7;
    --warning-200: #ffe69c;
    --warning-300: #FFE69C;
    --warning-400: #FFDF82;
    --warning-500: #FFD24F;
    --warning-600: #E5B72E;
    --warning-700: #997A1F;
    --warning-800: #80620D;
    --warning-900: #664C00;
    --destructive-50: #FFEBEB;
    --destructive-100: #FFD1D1;
    --destructive-200: #E39F9F;
    --destructive-300: #CC7A7A;
    --destructive-400: #C95B5B;
    --destructive-500: #BD4242;
    --destructive-600: #B82525;
    --destructive-700: #8C0E0E;
    --destructive-800: #660A0A;
    --destructive-900: #4B0000;

    --warning-bg-subtle: var(--warning-200);
    --primary: var(--primary-700);
    --secondary: var(--neutral-700);

    --default-font-family: "Roboto Flex Variable", "Roboto", "Helvetica", sans-serif;
    --default-font-size: 14px;
    --default-line-height: 20px;
    --headline-line-height: 36px;
    --small-font-size: 12px;
    --small-line-height: 16px;

    --mat-toolbar-title-text-font: var(--default-font-family);

    --mdc-theme-primary: var(--primary);
    --mdc-typography-font-family: var(--default-font-family);
}

div.branding {
    display: flex;
    align-items: center;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
}

h6 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    padding: 6px 0;
}

html, body {
    height: 100%;
}

body {
    background: var(--neutral-50);
    margin: 0;
    line-height: var(--default-line-height);
    font-size: var(--default-font-size);
    font-family: var(--default-font-family);
    font-weight: 400;
}

footer {
    color: var(--secondary);
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    padding: 8px 0;
}

footer p {
    margin-bottom: 8px;
}

footer a {
    color: var(--secondary);
}

.secondary {
    color: var(--secondary);
}

div.headline {
    margin-bottom: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 0 16px;
    line-height: var(--headline-line-height);
}

div.headline > * {
    margin: 0;
}

.spacer {
    flex: 1 1 auto;
}

.btn-small {
    height: 32px;
    line-height: 28px;
    padding: 0 12px;
}

label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
}

.form-text {
    color: black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.2px;
}

div.filepicker {
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    display: flex;
    width: 100%;
    height: 36px;
    margin: 2px 0;
    margin-bottom: 8px;
}

div.filepicker > input {
    margin-top: -1px;
    cursor: pointer;
    width: 100%;
}

div.filepicker > input::file-selector-button {
    position: relative;
    left: -9px;
    width: 100px;
    height: 36px;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #D4D4D4;
    background: var(--neutral-50);
    text-align: center;
    cursor: pointer;
}

div.filepicker > input.mdc-button {
    color: var(--neutral-800);
}

div.filepicker > input::file-selector-button:hover {
    background: var(--neutral-100);
}

mat-icon {
    vertical-align: middle;
}

input.mdc-button--raised:not(:disabled) {
    background-color: var(--primary-700);
    margin-top: 8px;
}

.mdc-button--outlined:not(:disabled) {
    border-width: 1px;
    border-color: var(--primary-500);
    border-style: solid;
}

div.card {
    margin: 8px 8px;
    padding: 16px 32px;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    background: #FFF;
}

div.alert {
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px 0px rgba(0, 0, 0, 0.20);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
}

div.alert-success {
    background: var(--success-50);
    color: var(--success-700);
}

div.alert-info {
    background: var(--primary-50);
    color: var(--primary-700);
}

div.alert-warning {
    background: var(--warning-50);
    color: var(--warning-700);
}

div.alert-danger {
    background: var(--destructive-50);
    color: var(--destructive-700);
}

mat-toolbar.navbar {
    background-color: var(--shade-0);
    border-bottom: 1px solid #D4D4D4;
    padding: 0 12.5%;
}

.app-title {
    color: var(--primary-700);
    text-align: center;
    font-size: 20px;
    font-weight: 850;
    line-height: 20px;
    padding-left: 2px;
    padding-right: 12px;
}

.navbar-spacing {
    flex-shrink: 1;
    flex-basis: 180px;
}

.navbar-logo {
    height: 100%;
    padding: 16px 20px;
}

div.navbar-logo > a > img {
    height: 35px;
}

div.navbar-tabs {
    display: flex;
    height: 100%;
}

a.navbar-item {
    color: var(--primary-400);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
    vertical-align: middle;
    padding: 22px;
    gap: 21px;
    display: flex;
    text-decoration: none;
    height: 100%
}

a.ftp-link {
    color: var(--primary-500);
}

a.navbar-item-active {
    color: var(--primary-700);
    border-bottom: 2px solid var(--primary-700);
}

a.navbar-item:hover {
    background-color: var(--primary-50);
}

a.navbar-item-disabled {
    color: var(--neutral-700);
}

div.date-dual > span {
    display: block;
    float: left;
    clear: left;
}

.subtext {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    margin: 0;
    color: var(--bs-secondary);
}

div.comingsoon {
    margin-top: -3.5px;
    font-size: 0.8em;
    height: 2.2em;
    padding: 4px 8px;
    background-color: var(--neutral-300);
    color: black;
    margin-left: -12px;
}

div.transfer-status > span {
    font-size: 0.8em;
    align-items: center;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 4px 8px;
}

div.transfer-status-approved > span {
    background: var(--success-200);
    color: var(--success-900);
}

div.transfer-status-pending > span {
    background: var(--primary-200);
    color: var(--primary-900);
}

div.transfer-status-rejected > span {
    background: var(--destructive-200);
    color: var(--destructive-900);
}

div.transfer-status-unknown > span {
    background: var(--warning-200);
    color: var(--warning-900);
}

.success-snackbar {
    --mdc-snackbar-container-color: var(--neutral-900);
    --mat-mdc-snack-bar-button-color: var(--primary-200);
    --mdc-snackbar-supporting-text-color: var(--neutral-50);
    --mat-snack-bar-button-color: var(--primary-200);
}

div.d-flex.transfer-log {
    flex-direction: row;
    padding-bottom: 16px;
}

table.mat-mdc-table {
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
}

.lastrefresh-subtext {
    text-align: center;
    margin-top: 8px;
    margin-right: 8px;
    color: var(--shade-100);
}

span.portal-link {
    font-family: 'Roboto', sans-serif;
}

div.download-link {
    display: flex;
    align-items: baseline;
}

div.download-filename {
    flex: 1 1 auto;
}

div.download-button {
    margin-left: auto;
}

a.download-link {
    color: var(--primary-500);
}

td.filename-truncate {
    max-width: 8em;
}

.filename-truncate {
    min-width: 8em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mat-mdc-standard-chip {
    --mdc-chip-elevated-container-color: var(--neutral-200);
}

.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary {
    --mdc-chip-elevated-container-color: var(--primary-500);
}

.dl-disabled {
    color: var(--neutral-500);
}

p.network-selection-infotext {
    margin-top: 8px;
    margin-left: 8px;
}

@media screen and (max-width: 768px) {
    .d-no-mobile {
        display: none !important;
    }

    mat-toolbar.navbar {
        padding: 0 8px;
        flex-wrap: wrap;
        height: 88px;
    }

    div.branding {
        width: 768px;
        height: 48px;
    }

    div.navbar-tabs {
        width: 100%;
        justify-content: center;
    }

    .navbar-logo {
        padding: 8px 12px;
    }

    div.navbar-logo > a > img {
        height: 30px;
    }

    a.navbar-item {
        height: 40px;
        line-height: 16px;
        padding: 10px 20px;
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
    }

    div.card {
        padding: 8px 16px;
    }
}
